export const enum QueryKeys {
  EventValueRangesForDevice = 'eventValueRangesForDevice',
  EventValueRangesForDeviceSensor = 'eventValueRangesForDeviceSensor',
  SpecificEventValueRange = 'specificEventValueRange',
  AddValueRange = 'addValueRange',
  UpdateValueRange = 'updateValueRange',
  UpdateValueRangePriority = 'updateValueRangePriority',
  DeleteValueRange = 'deleteValueRange',
  DevicePortsConfig = 'devicePortsConfig',
  GetDevicesPortsConfigsBatch = 'getDevicesPortsConfigsBatch',
  GetConsumptionExport = 'getConsumptionExport',
  MultipleReadingsExport = 'multipleReadingsExport',
  UseDeviceData = 'useDeviceData',
  GetDeviceConfig = 'getDeviceConfig',
  GetDevicesConfigsBatch = 'getDevicesConfigsBatch',
  GetUtilityRates = 'getUtilityRates',
  GetUtilityRate = 'getUtilityRate',
  CreateUtilityRate = 'createUtilityRate',
  UpdateUtilityRate = 'updateUtilityRate',
  DeleteUtilityRate = 'deleteUtilityRate',
  GeneralStatistics = 'generalStatistics',
  GetAllCompanies = 'getAllCompanies',
  UseDeviceLocationData = 'useDeviceLocationData',
  UseUpdateDeviceLocationData = 'useUpdateDeviceLocationData',
  GetConsumptionBillingReport = 'getConsumptionBillingReport',
  GetDeviceGroupsByCompanyId = 'getDeviceGroupsByCompanyId',
  GetDeviceGroupsFromParent = 'getDeviceGroupsFromParent',
  CreateDeviceGroup = 'createDeviceGroup',
  UpdateDeviceGroup = 'updateDeviceGroup',
  DeleteDeviceGroup = 'deleteDeviceGroup',
  AddDeviceSensorToGroup = 'addDeviceSensorToGroup',
  DeleteDeviceAndSensorFromGroup = 'deleteDeviceAndSensorFromGroup',
  GetDeviceGroupPercentageBreakdown = 'getDeviceGroupPercentageBreakdown',
  GetAlarmsExport = 'getAlarmsExport',
  GetActiveAlarmsForCompany = 'getActiveAlarmsForCompany',
  GetNumberOfActiveAlarms = 'getNumberOfActiveAlarms',
  GetSignalStatus = 'getSignalStatus',
  GetDevicesSignal = 'getDevicesSignal',
  GetDeviceBattery = 'getDeviceBattery',
  GetBatteryStatus = 'getBatteryStatus',
  UseRelayStatus = 'useRelayStatus',
  UseRelayStatuses = 'useRelayStatuses',
  GetDevicesFlow = 'getDevicesFlow',
  GetDevicesFlowAvg = 'getDeviceFlowAvg',
  GetDevicesReadings = 'getDevicesReadings',
  GetDevicesReadingsAvg = 'getDevicesReadingsAvg',
  GetCurrentReadings = 'getCurrentReadings',
  GetTimeSeriesPrediction = 'getTimeSeriesPrediction',
  GetDevicesIcons = 'getDevicesIcons',
  UpdateDeviceIcon = 'updateDeviceIcon',
  CreateDevice = 'createDevice',
  UpdateDevice = 'updateDevice',
  DeleteDevice = 'deleteDevice',
  BatchAddValueRanges = 'batchAddValueRanges',
  getTransmissions = 'getTransmissions',
  GetFeatureFlags = 'getFeatureFlags',
  getDashboard = 'getDashboard',
  UpdateSettings = 'updateSettings',
  GetDeviceGroupFlow = 'getDeviceGroupFlow',
  GetDeviceGroupFlowAggregated = 'getDeviceGroupFlowAggregated',
  GetDeviceGroupFlowAverageAggregated = 'getDeviceGroupFlowAverageAggregated',
  GetDeviceGroupStatistics = 'getDeviceGroupStatistics',
  GetDeviceGroupStatisticsTotal = 'getDeviceGroupStatisticsTotal',
  SensorTypeConfig = 'sensorTypeConfig',
  GetMap = 'getMap',
  UpdateMap = 'updateMap',
  GetUser = 'getUser',
  GetUsers = 'getUsers',
  UpdateUser = 'updateUser',
  CreateUser = 'createUser',
  DeleteUser = 'deleteUser',
  ResetPassword = 'resetPassword',
  GetIcons = 'getIcons',
  GetMapPins = 'getMapPins',
  CreateIcon = 'createIcon',
  UpdateIcon = 'updateIcon',
  GetCompanyInfo = 'getCompanyInfo',
  UpdateCompanyInfo = 'updateCompanyInfo',
  DeleteCompanyInfo = 'deleteCompanyInfo',
  GetClientCount = 'getClientCount',
  GetScheduledEventTaskExecutions = 'getScheduledEventTaskExecutions',
  GetScheduledEventBasedOnScheduledEventId = 'getScheduledEventBasedOnScheduledEventId',
  GetSensorAllowedUnits = 'getSensorAllowedUnits',
  GetLicenses = 'getLicenses',
  CreateLicense = 'createLicense',
  GetLicense = 'getLicense',
  ChangeLicenseActivity = 'changeLicenseActivity',
  CreateCompany = 'createCompany',
  DeleteCompany = 'deleteCompany',
  UpdateCompany = 'updateCompany',
  GetAllScheduledEventsInCompany = 'getAllScheduledEventsInCompany',
  GetScheduledEventsLogsForEvent = 'getScheduledEventsLogsForEvent',
  PatchScheduledEvent = 'patchScheduledEvent',
  DeleteScheduledEvent = 'deleteScheduledEvent',
  GetSpecificScheduledEventById = 'getSpecificScheduledEventById',
  GetDeviceLimits = 'getDeviceLimits',
  LinkDevice = 'linkDevice',
  UnlinkDevice = 'unlinkDevice',
  GetCustomerBillingReport = 'getCustomerBillingReport',
  GetTimezones = 'getTimezones',
  ValidateFormula = 'validateFormula',
  GetCountries = 'getCountries',
  GetCompany = 'getCompany',
  GetVersionInfo = 'getVersionInfo',
}
