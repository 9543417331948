import {
  Box,
  Checkbox,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { AxiosError } from 'axios';
import React, { FC } from 'react';
import Header from '../../components/header';
import { connect } from 'react-redux';
import { CompanySelectionActionCreator } from '../../state_management/actions/CompanySelectionActionCreator';
import { CompanySelectedAction } from '../../state_management/actions/CompanySelectionAction';
import { styles } from './Accounts.styles';
import SearchBar from '../../components/SearchBar/SearchBar';
import { TFunction, withTranslation } from 'react-i18next';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import ErrorUtil from '../../common/ErrorUtil';
import { getAllCompanies } from '../../clients/Company/CompanyClient';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import DataGridPagination from '@thingslog/ui-components/src/DataGrid/DataGridPagination';
import AuthUtil from '../../common/AuthUtil';
import { AuthState } from '../../reducers/auth_reducer';
import AccountActions from './components/AccountActions';
import {
  CompanyDto,
  CountriesListDto,
  CreateCompanyDto,
  LicenseDto,
  UpdateCompanyDto,
} from '@thingslog/repositories';
import { UseMutateFunction } from '@tanstack/react-query';
import { compose } from 'redux';
import ChildCompanies from './components/ChildCompanies';
import CreateAccountButton from './components/CreateAccountButton';
import { FeatureFlagState } from '../../reducers/feature_flag_reducer';
import { CompanySelectionState } from '../../reducers/company_reducer';

class Accounts extends React.Component<AccountsProps, AccountsState> {
  public state: AccountsState = {
    allCompanies: [],
    filteredCompanies: [],
    displayedCompanies: [],
    page: 0,
    size: 15,
    query: '',
    isLoaded: false,
  };

  public componentDidMount = async (): Promise<void> => {
    await this.loadAllCompanies();
  };

  public componentDidUpdate = async (prevProps: Readonly<AccountsProps>): Promise<void> => {
    if (prevProps.shouldRefresh !== this.props.shouldRefresh && this.props.shouldRefresh)
      await this.loadAllCompanies();
  };

  private isFeatureFlagOn = (flagName: string): boolean => {
    return this.props.featureFlags.featureFlagsArray.includes(flagName);
  };

  private loadAllCompanies = async (): Promise<void> => {
    this.setState({ isLoaded: false });
    try {
      const allCompanies = await getAllCompanies();
      const companies = this.moveSelectedCompanyToFront(allCompanies);
      this.setState({
        allCompanies: companies,
        filteredCompanies: companies,
        displayedCompanies: this.paginate(companies, this.state.page, this.state.size),
      });
    } catch (error) {
      ErrorUtil.handleError(error);
    }
    this.setState({ isLoaded: true });
  };

  private changeDisplayedCompanies = (page: number, size: number): void => {
    this.setState({
      page: page,
      size: size,
      displayedCompanies: this.paginate(this.state.filteredCompanies, page, size),
    });
  };

  private requestSearch = (query: string): void => {
    const filteredCompanies = this.state.allCompanies.filter((company: CompanyDto) =>
      [company.name, company.contactRef, company.accountRef].some(
        (companyFieldValue: string | null) =>
          companyFieldValue?.toUpperCase().includes(query.toUpperCase())
      )
    );

    this.setState({
      page: 0,
      filteredCompanies: filteredCompanies,
      displayedCompanies: this.paginate(filteredCompanies, 0, this.state.size),
    });
  };

  private cancelSearch = (): void => {
    this.setState({
      page: 0,
      query: '',
      filteredCompanies: this.state.allCompanies,
      displayedCompanies: this.paginate(this.state.allCompanies, 0, this.state.size),
    });
  };

  private onChangeSearch = (query: string): void => {
    this.setState({ query });
    this.requestSearch(query);
  };

  private handleRowSelected = (company: CompanyDto): void => {
    if (this.props.company.id === company.id) {
      localStorage.removeItem('selectedCompany'); //Unselect
      this.props.selectCompany({ id: null, name: null });
    } else {
      localStorage.setItem(
        'selectedCompany',
        JSON.stringify({ id: company.id, name: company.name })
      );
      this.props.selectCompany({ id: company.id, name: company.name }); // Select
    }
  };

  private paginate = (array: CompanyDto[], page: number, size: number): CompanyDto[] => {
    return array.slice(page * size).slice(0, size);
  };

  private changePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ): void => {
    this.changeDisplayedCompanies(page, this.state.size);
  };

  private changeRowsPerPage = (e: React.ChangeEvent): void => {
    this.changeDisplayedCompanies(0, e.target.value as number);
  };

  private moveSelectedCompanyToFront = (companies: CompanyDto[]): CompanyDto[] => {
    if (this.props.company.id) {
      companies.forEach((company: CompanyDto, index: number) => {
        if (company.id === this.props.company.id) {
          companies.unshift(companies.splice(index, 1)[0]);
        }
      });
    }
    return companies;
  };

  public render(): React.ReactNode {
    return (
      <Header>
        <Container>
          <Grid container justifyContent="center" spacing={1}>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <Typography>{this.props.t<string>('companies_number_of_accounts')}</Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    <Box fontWeight={600}>
                      {this.state.allCompanies.length > this.state.filteredCompanies.length &&
                        `${this.state.filteredCompanies.length} / `}
                      {this.state.allCompanies.length}
                    </Box>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {!this.state.isLoaded && (
              <Grid item xs={12}>
                <Grid container alignItems="center" justifyContent="center">
                  <Grid item>
                    <CircularProgress />
                  </Grid>
                </Grid>
              </Grid>
            )}
            {this.state.isLoaded && (
              <>
                <Grid item xs={12}>
                  <SearchBar
                    value={this.state.query}
                    onChange={this.onChangeSearch}
                    onRequestSearch={(): void => this.requestSearch(this.state.query)}
                    onCancelSearch={this.cancelSearch}
                    placeholder={this.props.t('companies_search')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Paper>
                    <TableContainer>
                      <Table size="small" stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell width="5%" size="small"></TableCell>
                            <TableCell size="small">
                              <Typography variant="subtitle1">
                                <Box fontWeight={600}>
                                  {this.props.t<string>('companies_table_name')}
                                </Box>
                              </Typography>
                            </TableCell>
                            {AuthUtil.hasRole(this.props.auth, 'SUPER_ADMIN') && (
                              <>
                                <TableCell size="small">
                                  <Typography variant="subtitle1">
                                    <Box fontWeight={600}>
                                      {this.props.t<string>('accounts_accountRef')}
                                    </Box>
                                  </Typography>
                                </TableCell>
                                <TableCell size="small">
                                  <Typography variant="subtitle1">
                                    <Box fontWeight={600}>
                                      {this.props.t<string>('accounts_contactRef')}
                                    </Box>
                                  </Typography>
                                </TableCell>
                              </>
                            )}
                            {(AuthUtil.hasRole(this.props.auth, 'SUPER_ADMIN') ||
                              AuthUtil.hasRole(this.props.auth, 'ADMIN')) && (
                              <>
                                <TableCell size="small">
                                  <Typography variant="subtitle1">
                                    <Box fontWeight={600}>
                                      {this.props.t<string>('accounts_child_companies')}
                                    </Box>
                                  </Typography>
                                </TableCell>
                                <TableCell size="small">
                                  {this.isFeatureFlagOn('CREATE_ACCOUNT_MODAL') ? (
                                    <CreateAccountButton
                                      company={this.props.company}
                                      timezones={this.props.timezones}
                                      countriesList={this.props.countries}
                                      createCompany={this.props.createCompanyMutation}
                                    />
                                  ) : (
                                    <Typography variant="subtitle1">
                                      <Box fontWeight={600}>
                                        {this.props.t<string>('company_manager_actions')}
                                      </Box>
                                    </Typography>
                                  )}
                                </TableCell>
                              </>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.displayedCompanies.map((company: CompanyDto) => {
                            return (
                              <>
                                <TableRow
                                  hover
                                  onClick={(): void => {
                                    this.handleRowSelected(company);
                                  }}
                                >
                                  <TableCell size="small" padding="checkbox">
                                    <Checkbox
                                      checked={this.props.company.id === company.id}
                                      onClick={(): void => {
                                        this.handleRowSelected(company);
                                      }}
                                      icon={<RadioButtonUncheckedIcon fontSize="small" />}
                                      checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                                      size="small"
                                    />
                                  </TableCell>
                                  <TableCell size="small">
                                    <Typography variant="subtitle1">{company.name}</Typography>
                                  </TableCell>

                                  {AuthUtil.hasRole(this.props.auth, 'SUPER_ADMIN') && (
                                    <>
                                      <TableCell size="small" width={140}>
                                        {company.accountRef}
                                      </TableCell>
                                      <TableCell size="small" width={140}>
                                        {company.contactRef}
                                      </TableCell>
                                    </>
                                  )}
                                  {(AuthUtil.hasRole(this.props.auth, 'SUPER_ADMIN') ||
                                    AuthUtil.hasRole(this.props.auth, 'ADMIN')) && (
                                    <>
                                      <TableCell size="small" width={240}>
                                        {company.childCompaniesNames.length !== 0 ? (
                                          <ChildCompanies
                                            childCompanies={company.childCompaniesNames}
                                          />
                                        ) : (
                                          <span>{this.props.t<string>('accounts_none')}</span>
                                        )}
                                      </TableCell>
                                      <TableCell size="small" width={140}>
                                        <AccountActions
                                          timezones={this.props.timezones}
                                          countriesListDto={this.props.countries}
                                          licensesForEditingCompany={
                                            this.props.licensesForEditingCompany
                                          }
                                          onSelectCompanyForEditing={
                                            this.props.onSelectCompanyForEditing
                                          }
                                          updateCompanyMutation={this.props.updateCompanyMutation}
                                          deleteCompanyMutation={this.props.deleteCompanyMutation}
                                          company={company}
                                        />
                                      </TableCell>
                                    </>
                                  )}
                                </TableRow>
                              </>
                            );
                          })}
                        </TableBody>
                      </Table>
                      <TablePagination
                        rowsPerPageOptions={[15, 25, 50, 100]}
                        component="div"
                        count={this.state.filteredCompanies.length}
                        rowsPerPage={this.state.size}
                        page={this.state.page}
                        onPageChange={this.changePage}
                        onRowsPerPageChange={this.changeRowsPerPage}
                        ActionsComponent={DataGridPagination as FC<TablePaginationActionsProps>}
                      />
                    </TableContainer>
                  </Paper>
                </Grid>
              </>
            )}
          </Grid>
        </Container>
      </Header>
    );
  }
}

interface AccountsProps extends AccountsStateProps, WithStyles<typeof styles> {
  licensesForEditingCompany: LicenseDto[] | undefined;
  onSelectCompanyForEditing: (companyId: number) => void;
  deleteCompanyMutation: UseMutateFunction<void, AxiosError<any, any>, string, unknown>;
  updateCompanyMutation: UseMutateFunction<void, AxiosError<any, any>, UpdateCompanyDto, unknown>;
  createCompanyMutation: UseMutateFunction<void, AxiosError<any, any>, CreateCompanyDto, unknown>;
  timezones: Set<string>;
  countries: CountriesListDto | null;
  shouldRefresh: boolean;
  t: TFunction<'translation', undefined>;
}

interface AccountsState {
  allCompanies: CompanyDto[];
  filteredCompanies: CompanyDto[];
  displayedCompanies: CompanyDto[];
  page: number;
  size: number;
  query: string;
  isLoaded: boolean;
}

interface AccountsStateProps {
  company: CompanySelectionState;
  auth: AuthState;
  featureFlags: FeatureFlagState;
}

interface AccountsDispatchProps {
  selectCompany: (company: CompanySelectionState) => CompanySelectedAction;
}

const mapStateToProps = (stateProps: {
  company: CompanySelectionState;
  auth: AuthState;
  featureFlags: FeatureFlagState;
}): AccountsStateProps => ({
  auth: stateProps.auth,
  company: stateProps.company,
  featureFlags: stateProps.featureFlags,
});

const dispatchToProps: AccountsDispatchProps = {
  selectCompany: CompanySelectionActionCreator.selectCompany,
};

const enhance = compose<React.ComponentType<AccountsProps>>(
  connect(mapStateToProps, dispatchToProps),
  withStyles(styles, { withTheme: true }),
  withTranslation()
);

export default enhance(Accounts);
