import React, { FC } from 'react';
import Header from '../../components/header';

const SensorsAndNetworkConfig: FC<SensorsAndNetworkConfigProps> = () => {
  return <Header></Header>;
};

interface SensorsAndNetworkConfigProps {}

export default SensorsAndNetworkConfig;
