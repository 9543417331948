import {
  Box,
  Checkbox,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { WithRouterProps, withRouter } from '../../common/withRouter';
import { getCompaniesForDevice } from '../../clients/Company/CompanyClient';
import { Company } from '../../model/API/Company/Company';
import { styles } from './DeviceSearch.styles';
import Header from '../../components/header';
import SearchBar from '../../components/SearchBar/SearchBar';
import { CompanySelectionActionCreator } from '../../state_management/actions/CompanySelectionActionCreator';
import { CompanySelectedAction } from '../../state_management/actions/CompanySelectionAction';
import { connect } from 'react-redux';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import ErrorUtil from '../../common/ErrorUtil';

class DeviceSearch extends React.Component<DeviceSearchProps, DeviceSearchState> {
  public state: DeviceSearchState = {
    companies: [],
    query: '',
  };

  private fetchData = async (query: string): Promise<void> => {
    try {
      let companies = await getCompaniesForDevice(query);
      this.setState({
        companies: companies,
      });
    } catch (error) {
      ErrorUtil.handleError(error);
    }
  };

  private onChangeSearch = (searchInput: string): void => {
    const query = searchInput.trim();
    this.setState({ query: searchInput });

    if (query.length === 8) {
      this.fetchData(query);
    } else {
      this.setState({ companies: [] });
    }
  };

  private cancelSearch = (): void => {
    this.setState({ query: '', companies: [] });
  };

  private handleRowSelected = (company: Company): void => {
    this.props.company.id === company.id
      ? this.props.selectCompany({ id: null, name: null }) // Unselect
      : this.props.selectCompany({ id: company.id, name: company.name }); // Select
  };

  public render = (): React.ReactNode => {
    return (
      <Header>
        <Container>
          <Grid container justifyContent="center" spacing={1}>
            <Grid item xs={12}>
              <SearchBar
                value={this.state.query}
                onChange={this.onChangeSearch}
                onCancelSearch={this.cancelSearch}
                placeholder={this.props.t('device_search_device_number')}
              />
            </Grid>
            {this.state.companies.length > 0 && (
              <Grid item xs={12}>
                <Paper>
                  <TableContainer>
                    <Table size="small" stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell width="5%" size="small"></TableCell>
                          <TableCell size="small">
                            <Typography variant="subtitle1">
                              <Box fontWeight={600}>
                                {this.props.t<string>('companies_table_name')}
                              </Box>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.companies.map((company: Company) => {
                          return (
                            <TableRow
                              key={company.id}
                              hover
                              onClick={(): void => {
                                this.handleRowSelected(company);
                              }}
                            >
                              <TableCell size="small" padding="checkbox">
                                <Checkbox
                                  checked={this.props.company.id === company.id}
                                  onClick={(): void => {
                                    this.handleRowSelected(company);
                                  }}
                                  icon={<RadioButtonUncheckedIcon fontSize="small" />}
                                  checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                                  size="small"
                                />
                              </TableCell>
                              <TableCell size="small">
                                <Typography variant="subtitle1">{company.name}</Typography>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            )}
          </Grid>
        </Container>
      </Header>
    );
  };
}

interface DeviceSearchProps
  extends WithRouterProps,
    WithTranslation,
    DeviceSearchStateProps,
    DeviceSearchDispatchProps,
    WithStyles<typeof styles> {}

interface DeviceSearchState {
  query: string;
  companies: Company[];
}

interface DeviceSearchStateProps {
  company: { id: number | null; name: string | null };
}

interface DeviceSearchDispatchProps {
  selectCompany: (company: { id: number | null; name: string | null }) => CompanySelectedAction;
}

const mapStateToProps = (stateProps: { company: any }): DeviceSearchStateProps => ({
  company: stateProps.company,
});

const dispatchToProps: DeviceSearchDispatchProps = {
  selectCompany: CompanySelectionActionCreator.selectCompany,
};

export default withRouter(
  connect(
    mapStateToProps,
    dispatchToProps
  )(withStyles(styles, { withTheme: true })(withTranslation()(DeviceSearch)))
);
